
import React from "react"
import Layout from "./../../components/Layout"
import { Helmet } from "react-helmet"
import App from "./../../nav/App"

const AppPage = () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>WYNIT | Télécharge l'appli</title>
        <meta
          name="description"
          content="WYNIT, les bons plans marseillais pour les 15-30 ans. Sur cette page,
          tu trouveras un formulaire pour te connecter à ton compte WYNIT."
        />
        <meta name="keywords" content="réductions,jeunes,connexion,compte" />
        <link rel="canonical" href="https://wynit.com/app" />
      </Helmet>
      <App />
    </Layout>
  )
}

export default AppPage