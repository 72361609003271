import * as rdd from "react-device-detect"

// Check if window is defined
const isBrowser = typeof window !== "undefined";

const App = () => {
  if (isBrowser) {
    if (rdd.osName === "Android") {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.wynit.app"
    } else if (rdd.osName === "iOS") {
      window.location.href = "https://apps.apple.com/fr/app/wynit/id1576761700"
    } else {
      window.location.href = "/"
    }
  }
  return null
}

export default App